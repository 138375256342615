import { GiftMediaType, SendStatus, SendType } from 'shared/types/databaseEnums'
import { GiftDraftID, LegacyGiftDraftInput } from 'shared/types/giftTypes'
import { GIFT_DRAFT_COOKIE } from 'shared/constants/filenames'
import { ReadonlyRequestCookies } from 'next/dist/server/web/spec-extension/adapters/request-cookies'
import { ReadonlyURLSearchParams } from 'next/navigation'
import { setCookie, parseCookies, destroyCookie } from 'nookies'

export const BASE_GIFT_BUILDER_URL = '/create-v3' // change to '/create' at launch time

export const emptyGiftDraft: LegacyGiftDraftInput = {
  category: '',
  value: 5000,
  media: [
    {
      type: GiftMediaType.Text,
      message: 'temporary, delete me',
    },
  ],
  receiverContact: { contactType: 'EMAIL', name: '' },
  senderContact: { contactType: 'EMAIL', name: '', email: '' },
  sendDetails: {
    sendType: SendType.SendUponPurchase,
    sendMethod: 'EMAIL',
    sendStatus: SendStatus.ScheduledToSend,
  },
}

export function base64EncodeJSON(data: any): string {
  return Buffer.from(JSON.stringify(data)).toString('base64')
}

export function decodeBase64ToJSON<T>(dataString: string): T {
  return JSON.parse(Buffer.from(dataString, 'base64').toString()) as T
}

export function clientFetchLocalSavedGiftDraft(draftID?: string) {
  if (!draftID) return undefined
  const encodedDraftCookie = parseCookies()[`${GIFT_DRAFT_COOKIE}_${draftID}`]
  if (!encodedDraftCookie) return undefined

  // console.log('found cookie')
  return decodeBase64ToJSON<{ giftDraft: LegacyGiftDraftInput }>(
    encodedDraftCookie,
  ).giftDraft
}

export function serverFetchLocalSavedGiftDraft(
  cookies: ReadonlyRequestCookies,
  draftID?: string,
): LegacyGiftDraftInput | undefined {
  if (!draftID) return undefined
  const encodedDraftCookie = cookies.get(`${GIFT_DRAFT_COOKIE}_${draftID}`)
  if (!encodedDraftCookie) return undefined

  // console.log('found cookie')
  return decodeBase64ToJSON<{ giftDraft: LegacyGiftDraftInput }>(
    encodedDraftCookie.value,
  ).giftDraft
}

export function writeLocalSavedGiftDraft({
  // router,
  giftDraft,
  giftDraftID,
}: {
  // router: AppRouterInstance
  giftDraftID: GiftDraftID
  // pathName: string
  giftDraft: LegacyGiftDraftInput
}) {
  // router.replace(`${pathName}?${newSearchParams.toString()}`)
  const timestamp = new Date().toISOString()
  setCookie(
    null,
    `${GIFT_DRAFT_COOKIE}_${giftDraftID}`,
    base64EncodeJSON({ timestamp, giftDraft }),
    {
      maxAge: 12 * 60 * 60,
      path: '/',
    },
  )

  deleteOldCookies()
}

function deleteOldCookies() {
  const rawCookies = parseCookies()
  const keys = Object.keys(rawCookies).filter((name) =>
    name.startsWith(GIFT_DRAFT_COOKIE),
  )
  if (keys.length <= 5) return

  const keysToKeep = keys
    .map((key) => ({
      key,
      value: decodeBase64ToJSON<{
        giftDraft: LegacyGiftDraftInput
        timestamp: string
      }>(rawCookies[key]),
    }))
    .sort((a, b) => b.value.timestamp.localeCompare(a.value.timestamp))
    .slice(0, 5)
    .map(({ key }) => key)

  const keysToDelete = keys.filter((k) => !keysToKeep.includes(k))
  for (let key of keysToDelete) {
    console.log('destroying cookie', key)
    destroyCookie({}, key, {
      path: '/',
    })
  }
}

export const INPUT_DEBOUNCE_DELAY = 500

// async function onSubmitClicked() {
//   if (!hasCheckoutBeenClicked) {
//     setHasCheckoutBeenClicked(true)
//     setIsCheckoutDisabled(checkErrorState())
//   }
//   const errorState = checkErrorState()
//   logAnalyticsEvent('attempt_begin_checkout', {
//     currency: 'USD',
//     value,
//     failed: errorState,
//     items: [
//       {
//         item_id: categoryDescription.id,
//         item_name: categoryDescription.nameOnCard,
//       },
//     ],
//   })
//   if (errorState) return
//   if (isCheckoutDisabled) return
//   setHasCheckoutSuccessfullyProgressed(true)
//   const giftDraft: GiftDraftInput = {
//     category: categoryDescription.id,
//     senderContact: senderContact as SenderContactInfo,
//     receiverContact,
//     value: value * 100,
//     media: mediaFromMediaModalsAndNote(mediaModals, note),
//     sendDetails,
//   }

//   if (senderContact.email) setAnalyticsTag('user_email', senderContact.email)

//   logAnalyticsEvent('begin_checkout', {
//     currency: 'USD',
//     value,
//     items: [
//       {
//         item_id: categoryDescription.id,
//         item_name: categoryDescription.nameOnCard,
//       },
//     ],
//   })

//   createGiftDraft(giftDraft)
//     .then((gift) => {
//       router.push(`/${categoryURLFromID(gift.category)}/${gift.id}/checkout`)
//     })
//     .catch((error) => {
//       console.error('Gift creation error', error)
//       if (error.status === 503) {
//         router.push('/errors/down-for-maintenance')
//       }
//     })
// }

// function loadStoredDraft(draftId: string) {
//   const rawCookie = parseCookies()[GIFT_DRAFT_COOKIE + '_' + draftId]
//   if (rawCookie) {
//     const cookie = JSON.parse(rawCookie)
//     setSenderContact(cookie.senderContact)
//     setReceiverContact(cookie.receiverContact)
//     setValue(Math.round(cookie.value))
//     setSendDetails(cookie.sendDetails)
//     setNote(cookie.note)
//     cookie.mediaModals.forEach((modal: { media: MediaUnitRender }) => {
//       if (modal.media && modal.media.type != 'GIF') {
//         modal.media.localURL = ''
//       }
//     })
//     mediaModalDispatch({
//       type: 'SET_ALL_MEDIA',
//       mediaModals: cookie.mediaModals,
//     })
//   }
// }

// function updateSavedDraft() {
//   if (router.isReady) {
//     setTimeout(() => {
//       if (!router.query || !router.query.draftId) {
//         const newDraftId = new Date().valueOf()
//         router.replace(
//           {
//             pathname: router.pathname,
//             query: {
//               ...router.query,
//               draftId: newDraftId,
//             },
//           },
//           undefined,
//           { shallow: true },
//         )
//       }

//       if (router.query != undefined && router.query.draftId != undefined) {
//         const { draftId } = router.query
//         setCookie(
//           null,
//           GIFT_DRAFT_COOKIE + '_' + draftId,
//           JSON.stringify({
//             category: categoryDescription.id,
//             senderContact,
//             receiverContact,
//             assets: {},
//             value: value,
//             note: note,
//             mediaModals: mediaModals,
//             sendDetails,
//           }),
//           { maxAge: 5 * 24 * 60 * 60 },
//         )
//       }
//     }, 1000)
//   }
// }
