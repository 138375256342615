'use client'
import { useEffect, useState } from 'react'
import { auth } from '../client/firebase'
import { User_Secure } from 'shared/types/userTypes'
import {
  logAnalyticsEvent,
  setAnalyticsTag,
  setAnalyticsUserID,
} from 'lib/utils/analytics'
import { UserContext } from './UserContext'
import { User as FirebaseAuthUser, onIdTokenChanged } from 'firebase/auth'
import { getUserData } from 'lib/client/pagesAuthApi'
import { get } from 'shared/utils/api'

export interface UserProviderProps {
  userInput?: User_Secure
  children: React.ReactNode
}

export const UserProvider: React.FunctionComponent<UserProviderProps> = ({
  userInput,
  children,
}) => {
  const [user, setUser] = useState<User_Secure | undefined>(userInput)

  useEffect(() => {
    if (user) {
      setAnalyticsUserID(user.id)
      setAnalyticsTag('user_email', user.email?.primary || 'undefined')
      logAnalyticsEvent('user_data_fetched', {
        user_id: user.id,
        non_interaction: true,
      })
    }
  }, [user])

  useEffect(() => {
    const unsubscribeIdTokenListener = onIdTokenChanged(
      auth,
      handleIdTokenChanged,
    )
    return () => {
      unsubscribeIdTokenListener()
    }
  })

  const handleIdTokenChanged = async (
    firebaseUser: FirebaseAuthUser | null,
  ) => {
    if (!firebaseUser) {
      setUser(undefined)
      get('/api/logout')
      return
    }
  }

  // Fetch latest user from Sputnik
  const updateUser = async () => {
    if (!auth.currentUser) {
      return Promise.reject(new Error('No current user'))
    }
    try {
      const user = await getUserData()
      setUser(user)
      return user
    } catch (e) {
      return Promise.reject(e)
    }
  }

  return (
    <UserContext.Provider
      value={{
        user,
        updateUser,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
