import { createContext, useContext } from 'react'
import { User_Secure } from 'shared/types/userTypes'
import { User as FirebaseAuthUser } from 'firebase/auth'

//github.com/awinogrodzki/next-firebase-auth-edge
export const UserContext = createContext<{
  user?: User_Secure
  updateUser: () => Promise<User_Secure>
}>({
  updateUser: () =>
    new Promise((res, rej) => {
      rej('UserContext not set up')
    }),
})

export function useUser() {
  return useContext(UserContext)
}
