'use client'
import { useEffect, useRef, useState } from 'react'
import { usePathname, useSearchParams } from 'next/navigation'
import { GoogleAnalytics } from '@next/third-parties/google'

import {
  load as loadIntercom,
  boot as bootIntercom,
  update as updateIntercom,
  shutdown as shutdownIntercom,
} from '../lib/intercom/intercom'

import Script from 'next/script'
import * as gtag from '../lib/utils/analytics'
import { useUser } from 'lib/auth/UserContext'
import { sputnikGet } from 'shared/sputnik/client'
import { sputnikGetWithCache } from 'lib/client/pagesAuthApi'
import { IN_APP_VIEW_PARAM } from 'shared/constants/urlConstants'
import { User_Secure } from 'shared/types/userTypes'
import logger from 'lib/utils/logger.client'
import { BASE_GIFT_BUILDER_URL } from './[category]/(gift-builder-v3)/(utilities)/giftBuilderUtils'

export default function ScriptRunner() {
  const searchParams = useSearchParams()
  const inAppView = searchParams?.get(IN_APP_VIEW_PARAM) === 'true'
  if (!inAppView) return <Scripts />
}

// given a url, returns true if intercom should be hidden
function shouldHideIntercom(pathName?: string | null): boolean {
  if (!pathName) return false
  if (
    pathName.includes(BASE_GIFT_BUILDER_URL) &&
    !pathName.includes('/checkout')
  )
    return true
  return false
}

type IntercomState = 'uninitialized' | 'initializing' | 'initialized'

const Scripts = () => {
  const searchParams = useSearchParams()
  const pathname = usePathname()

  const { user } = useUser()
  const intercomState = useRef<IntercomState>('uninitialized')
  const hidingIntercom = useRef<boolean>(false)

  useEffect(() => {
    const url = `${pathname}?${searchParams}`
    gtag.pageview(url)
    if (
      hidingIntercom.current !== shouldHideIntercom(pathname) &&
      intercomState.current === 'initialized'
    ) {
      if (user) {
        getIntercomOptions(user).then((options) => {
          updateIntercom(updateIntercomOptionsWithHideState(options))
        })
      } else {
        updateIntercom(updateIntercomOptionsWithHideState({}))
      }
    }
  }, [pathname, searchParams])

  const updateIntercomOptionsWithHideState = (intercomOptions: any) => {
    const hide_default_launcher = shouldHideIntercom(pathname ?? undefined)
    hidingIntercom.current = hide_default_launcher
    return {
      ...intercomOptions,
      hide_default_launcher,
    }
  }

  const getIntercomOptions = async (user: User_Secure) => {
    const {
      data: { userHash },
    } = await sputnikGetWithCache<{ userHash: string }>(
      '/v1/secure/user/intercom-id?platform=web',
    ).catch((error) => {
      logger.error(
        'App router: unable to get Intercom options to initialize Intercom',
        { error },
      )
      throw error
    })
    return {
      user_id: user.id,
      user_hash: userHash,
      name: user.names.givenName + ' ' + user.names.familyName,
      email: user.email.primary,
      phone: user.phone.primary,
    }
  }

  const initializeIntercom = async () => {
    loadIntercom()
    try {
      const options = user ? await getIntercomOptions(user) : {}
      bootIntercom(updateIntercomOptionsWithHideState(options))
      intercomState.current = 'initialized'
    } catch (e) {
      intercomState.current = 'uninitialized'
    }
  }

  useEffect(() => {
    if (typeof window !== 'undefined') {
      if (intercomState.current === 'uninitialized') {
        intercomState.current = 'initializing'
        setTimeout(() => {
          initializeIntercom().then()
        }, 5000)
      } else if (intercomState.current === 'initialized') {
        if (user) {
          getIntercomOptions(user).then((options) => {
            updateIntercom(updateIntercomOptionsWithHideState(options))
          })
        } else {
          shutdownIntercom()
          bootIntercom(updateIntercomOptionsWithHideState({}))
        }
      }
    }
  }, [user])

  if (process.env.NEXT_PUBLIC_ENVIRONMENT.startsWith('development')) {
    return <></>
  }

  return (
    <>
      <GoogleAnalytics gaId={gtag.GA_TRACKING_ID || ''} />
      <Script
        id="clarity-init"
        dangerouslySetInnerHTML={{
          __html: `
          (function(c,l,a,r,i,t,y){
            c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
            t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i;
            y=l.getElementsByTagName(r)[0];y.parentNode.insertBefore(t,y);
        })(window, document, "clarity", "script", 'k7ipn76fam');`,
        }}
      />
    </>
  )
}
