import { getApp, getApps, initializeApp } from 'firebase/app'
import {
  connectAuthEmulator,
  initializeAuth,
  browserLocalPersistence,
} from 'firebase/auth'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'

// TODO: tighten API key quota: https://firebase.google.com/docs/projects/api-keys#apply-restrictions-password-based-auth
const firebaseConfig = {
  apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  // databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
  measurementId: process.env.NEXT_PUBLIC_GA_ID,
}

if (!getApps().length) {
  initializeApp(firebaseConfig)
}

export const app = getApp()

const auth = initializeAuth(app, {
  persistence: browserLocalPersistence,
})
if (process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST) {
  connectAuthEmulator(auth, process.env.NEXT_PUBLIC_FIREBASE_AUTH_EMULATOR_HOST)
}
export { auth }

export function uploadImage(
  file: File,
  destinationPath: string,
  newFileName?: string,
): Promise<{ asset: string; url: string }> {
  const fileName =
    newFileName ?? Math.floor(Math.random() * Math.pow(10, 32)).toString(36)
  console.log(fileName)
  const storage = getStorage()
  const storageRef = ref(storage, `${destinationPath}${fileName}`)

  return uploadBytes(storageRef, file)
    .then((snapshot) => {
      return getDownloadURL(storageRef)
    })
    .then((url) => {
      return { url, asset: fileName }
    })
    .catch((error) => {
      throw new Error('error uploading image')
    })
}
