import { getAuth } from 'firebase/auth'
import logger from 'lib/utils/logger.client'
import { parseCookies, setCookie } from 'nookies'
import { sputnikGet, sputnikPut } from 'shared/sputnik/client'
import type { LegacyGiftDraft } from 'shared/types/giftTypes'
import type { User_Secure } from 'shared/types/userTypes'

// Update gift
export function updateGift(gift: LegacyGiftDraft) {
  return authPut('/api/gift', gift)
}

export type RequestCacheOptions = {
  timeout?: number // max cach age in seconds
  cookieName?: string // if not provided, cookie name is a lightly hashed version of the url
  skipCache?: boolean // if true, will skip checking cache
}

export function sputnikGetWithCache<ResponseData>(
  url: string,
  cacheOptions?: RequestCacheOptions,
): Promise<{ data: ResponseData; cacheHit: boolean }> {
  const timeout = cacheOptions?.timeout ?? 5 * 60 // default to 5 minutes
  const cookieName =
    cacheOptions?.cookieName ?? Buffer.from(url).toString('base64')
  const skipCache = cacheOptions?.skipCache ?? false

  try {
    const cookies = parseCookies()
    if (!skipCache && cookieName in cookies) {
      const cache: {
        data: ResponseData
      } = JSON.parse(Buffer.from(cookies[cookieName], 'base64').toString())
      // if (new Date(cache.timestamp).getTime() >= new Date().getTime())
      return Promise.resolve({ data: cache.data, cacheHit: true })
    }
  } catch (error) {
    logger.error('Error parsing request data cache', { error })
  }

  return sputnikGet<ResponseData>(url).then((data) => {
    if (!data) return { data, cacheHit: false }

    const encodedCookie = Buffer.from(JSON.stringify({ data })).toString(
      'base64',
    )
    setCookie(null, cookieName, encodedCookie, {
      maxAge: timeout,
    })
    return { data, cacheHit: false }
  })
}

export function getUserData() {
  return sputnikGet<{ user: User_Secure }>('/v1/secure/user').then(
    ({ user }) => {
      if (!user) throw new Error('User data not found')
      return user
    },
  ) as Promise<User_Secure>
}

export function updateUserData(user: Partial<User_Secure>) {
  return sputnikPut<any>('/v1/secure/user', user).then(({ updated }) => {
    if (!updated) throw new Error('User not updated')
    return updated
  }) as Promise<User_Secure>
}

// make an authenticated POST request
export function authGet(url: string): Promise<any> {
  return _authReq(url, {
    method: 'GET',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  }).then((response) => {
    if (response.ok) return response.json()
    throw response
  })
}

export function authGetRaw(url: string) {
  return _authReq(url, {
    method: 'GET',
  }).then((response) => {
    if (response.ok) return response
    throw response
  })
}

// make an authenticated POST request
export function authPost(url: string, body: any) {
  return _authReq(url, {
    body: JSON.stringify(body),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  }).then((response) => {
    if (response.ok) return response.json()
    throw response
  })
}

// make an authenticated PUT request
export function authPut(url: string, body: any) {
  return _authReq(url, {
    body: JSON.stringify(body),
    method: 'PUT',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
  }).then((response) => response.json())
}

// Make a request with the user's auth token in the header
function _authReq(url: string, options: RequestInit) {
  const auth = getAuth()
  if (!auth.currentUser) return Promise.reject(new Error('No user logged in'))
  return auth.currentUser
    .getIdToken()
    .then((token) => {
      const newOptions = {
        ...options,
        headers: {
          ...options.headers,
          Authorization: `Bearer ${token}`,
        },
      }
      return fetch(url, newOptions)
    })
    .catch((e) => {
      throw new Error('Invalid user logged in', e)
    })
}
