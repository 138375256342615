// how does the gift get sent
export enum SendType {
  ScheduledSend = 'SCHEDULED_SEND',
  SendUponPurchase = 'SEND_UPON_PURCHASE',
  // LinkShare = "LINK_SHARE", // no link share in MVP
}

// these are integers so that we can use the firebase increment value to atomically progress through the send flow.
export enum SendStatus {
  ScheduledToSend = 0, // the gift has not been sent yet but is scheduled to be sent.
  Sending = 1, // the gift is in the process of being sent, think of this like a lock.
  Sent = 2, // the gift has been sent
}

// type of the media object in the media array
export enum GiftMediaType {
  Text = 'TEXT', // in the MVP, there will only be one text object at the beginning of the media array
  Video = 'VIDEO',
  Image = 'IMAGE',
  Gif = 'GIF',
}

export enum DiscountType {
  Referral = 'referral-discount',
  PromoCode = 'promo-code',
}

export enum DiscountTriggerType {
  Automatic = 'automatic', // allways available to any user
  InputCode = 'input-code', // triggered by a code input at checkout by the user
  Cookie = 'cookie',
  EmailMatch = 'email-match',
}

export enum CardColor {
  Blue = 'BLUE',
  Pink = 'PINK',
  Green = 'GREEN',
  Purple = 'PURPLE',
  Orange = 'ORANGE',
}
